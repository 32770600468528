import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { StaticQuery, graphql, Link, navigate } from "gatsby"
import { v4 as uuid } from "uuid"
import { addProduct } from "../../actions/index"

class MesCommandes extends React.Component {
  constructor(props) {
    super(props)
    this.state = { url: process.env.GATSBY_API_URL + "/index.php?controller=pdf-invoice&id_order=" }
  }

  handleSelected = (products, event, target = null) => {
    event.preventDefault()

    products.map(p => {
      // Dans l'idéal il faudrait vérifier que le produit existe toujours
      this.props.dispatch(
        addProduct({
          _id: `product_${uuid()}`,
          ...JSON.parse(p.json.replace(/(\r\n|\n|\r)/gm, "")),
        })
      )
    })

    if (typeof window !== "undefined") {
      if (target == "devis") {
        navigate(`/apps/commande`)
      } else {
        navigate(`/panier`)
      }
    }
  }

  render() {
    return (
      <>
        <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB" }}>
          {this.props.commandes.length > 0 ? (
            <>
              <div className="commandes header column is-2">Référence de commande</div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-1">
                Date
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-1">
                Prix total HT
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-1">
                Prix total TTC
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-2">
                Paiement
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-2">
                Etat
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-1">
                Facture
              </div>
              <div style={{ borderLeft: "0" }} className="commandes header column is-3"></div>{" "}
            </>
          ) : (
            "Aucune commande"
          )}
        </div>

        {this.props.commandes.map((order, index) => (
          <div className="columns commandes line" key={index}>
            <div className="column is-2">
              <span className="is-hidden-desktop">
                Commande N°<b>{order.reference}</b> du <b>{order.date_add}</b>
              </span>
              <span className="is-hidden-touch">{order.reference}</span>
            </div>
            <div className="column is-1 is-hidden-touch">{order.date_add}</div>
            <div className="column is-1">
              <span className="is-hidden-desktop">Total :&nbsp;</span>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(order.total_paid / 1.2))}
            </div>
            <div className="column is-1">
              <span className="is-hidden-desktop">Total :&nbsp;</span>
              {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(order.total_paid))}
            </div>
            <div className="column is-2">
              <span className="is-hidden-desktop">Moyen de paiement ou devis :&nbsp;</span>
              {order.payment}
            </div>
            <div className="column is-2">
              <span className="is-hidden-desktop">&Eacute;tat :&nbsp;</span>
              <span style={{ background: order.color, color: "#fff", padding: 4 }}>{order.name}</span>
            </div>
            <div className="column is-1">{order.valid == 1 ? <a href={`${this.state.url}${order.id_order}&secure_key=${order.secure_key}`}>PDF</a> : ""}</div>
            <div className="column is-3">
              <Link to={`/apps/mon-compte/commande/${order.id_order}`}>Détails</Link>
              {/*<br></br>
              <a href="#f" onClick={() => this.handleSelected(order.products, event)}>
                Commander à nouveau
              </a>*/}
              <br></br>
              {order.order_history[0] && order.order_history[0].id_order_state == 2104 ? (
                <a href="#f" onClick={() => this.handleSelected(order.products, event, "devis")}>
                  Accepter le devis
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </>
    )
  }
}

MesCommandes.propTypes = {
  dispatch: PropTypes.func,
  commandes: PropTypes.array,
  cart: PropTypes.object,
  mesProduitsCommande: PropTypes.object,
}

/*export default MesCommandes*/

function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
  }
}

const ConnectFunction = connect(mapStateToProps)(MesCommandes)

const StaticMesProduitsCommande = props => (
  <StaticQuery
    query={graphql`
      query produitCommande {
        allMysqlNewProduit(filter: { isDoublo: { eq: 0 } }) {
          edges {
            node {
              id
              ba_id
              ba_descriptif
              ba_photo_longueur
              ba_croquis
              bas_reference
              co_id
              co_libelle
              fa_id
              fa_libelle

              ba_prix_fourniture
              ba_prix
              ba_prix_majoration
              ba_largeur
              ba_chutes
            }
          }
        }

        allMysqlPassePartout {
          edges {
            node {
              pa_id
              pat_libelle
              pat_id
              pa_libelle
              pa_description
              pa_reference
            }
          }
        }

        allMysqlAccessoires {
          edges {
            node {
              pa_id
              pat_libelle
              pat_id
              pa_libelle
              pa_description
              pa_reference
              ba_photo_longueur
            }
          }
        }
      }
    `}
    render={mesProduitsCommande => <ConnectFunction mesProduitsCommande={mesProduitsCommande} {...props} />}
  />
)

export default StaticMesProduitsCommande
