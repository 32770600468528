import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

class RecapPaypal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <div style={{ marginTop: "9rem" }}>
          <p>
            <h2>Votre commande sur france-cadre.fr a bien été enregistrée.</h2>
            <br />
            <br />
            Votre référence de commande est la suivante : <span>{this.props.dataRecap.id_order}</span>.
            <br />
            <br />
            Un e-mail contenant ces informations vous a été envoyé.
            <br />
            <br />
            <span>Vous serez informé de l&apos;expédition de votre commande.</span>
            <br />
            <br />
            Pour toute question ou information complémentaire merci de contacter notre support client.
          </p>
        </div>
      </>
    )
  }
}

RecapPaypal.propTypes = {
  dataRecap: PropTypes.object,
}

//export default RecapPaypal;
function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(RecapPaypal)
