import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { fetchPasseInit, login, refreshAuthTrue } from "./../../actions"
import { useDispatch, useSelector } from "react-redux"
import { clearLoading } from "../../actions/auth"

export const MesDonneesPerso = props => {
  const identifiants = useSelector(s => s.identifiants).identifiants
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    id_gender: parseInt(identifiants.id_gender),
    firstname: identifiants.firstname,
    lastname: identifiants.lastname,
    dNaissance: identifiants.birthday,
    email: identifiants.email,
    mdp: "",
    Nmdp: "",
    CNmdp: "",
  })
  const dispatch = useDispatch()

  const onChange = event => {
    if (event.target.name === "firstname" || event.target.name === "lastname") {
      setState({ ...state, [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]/g, "") })
    } else {
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }

  const downloadTxtFile = event => {
    event.preventDefault()
    var donneesPerso = props.donneesPersoATelecharger
    var text = "*** Données Personnelles ***\n\n"
    text += "Prénom : " + donneesPerso.lastname + "\n"
    text += "Nom : " + donneesPerso.firstname + "\n"
    text += "E-mail : " + donneesPerso.email + "\n"
    text += "Entreprise : " + donneesPerso.company + "\n"
    text += "Siret : \n"
    text += "Date de naissance  : " + donneesPerso.birthday + "\n"
    text += "Site web : \n\n\n"

    text += "*** Adresses ***\n\n"

    donneesPerso.addresses.map(adresse => {
      text += "------------------------------\n"
      text += "Alias : " + adresse.alias + "\n"
      text += "Entreprise : " + donneesPerso.company + "\n"
      text += "Prénom : " + donneesPerso.lastname + "\n"
      text += "Nom : " + donneesPerso.firstname + "\n"
      text += "Adresse : " + adresse.address1 + "\n"
      text += "Code postale : " + adresse.postcode + "\n"
      text += "Ville : " + adresse.city + "\n"
      text += "Pays : " + adresse.name_country + "\n"
      text += "Autre : " + adresse.address2 + "\n"
      text += "N° téléphone : " + adresse.phone + "\n"
      text += "N° téléphone mobile : " + adresse.phone_mobile + "\n"
      text += "N° TVA : \n\n"
    })

    const element = document.createElement("a")
    const file = new Blob([text], { type: "text/plain" })
    element.href = URL.createObjectURL(file)
    element.download = "données_personnelles.txt"
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  const handleUploadfile = async event => {
    event.preventDefault()
    setLoading(true)

    const data = {}
    data.id_gender = parseInt(state.id_gender)
    data.firstname = state.firstname
    data.lastname = state.lastname
    data.dNaissance = state.dNaissance
    data.mdp = state.mdp
    data.Nmdp = state.Nmdp
    data.CNmdp = state.CNmdp
    data.email = state.email

    try {
      let result = await fetch(process.env.GATSBY_API_URL + "/index.php?controller=authentification", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateInfos",
          email: identifiants.email,
          hash: identifiants.hash,
          site: process.env.SITE,
          data: data,
        }),
      })
      result = await result.json()
      if (typeof result.message !== "undefined" && result.message === "mailused") {
        setError("L'adresse mail est déjà utilisée !")
        dispatch(clearLoading())
      } else {
        dispatch(login(result))
      }
      setSuccess(true)
    } catch (err) {
      setSuccess(false)
      setError("Une erreur s'est produite !")
    }
    setLoading(false)
  }

  useEffect(() => {
    dispatch(fetchPasseInit())
    dispatch(refreshAuthTrue())
  }, [])

  return (
    <>
      <ul className="column is-12">
        <div className="columns is-multiline is-centered">
          <div className="column is-10">
            {loading.loading ? (
              <div className="modal" style={{ display: "flex", textAlign: "center" }}>
                <div className="modal-background"></div>
                <div className="modal-content">
                  <button
                    className="button is-loading"
                    style={{
                      background: "transparent",
                      border: "1px solid transparent",
                    }}
                  >
                    Loading
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            <form method="post" onSubmit={handleUploadfile}>
              <div className="field">
                <div className="control">
                  <label className="label" htmlFor="id_gender">
                    Civilité&nbsp;:&nbsp;&nbsp;
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={onChange} value="1" defaultChecked={state.id_gender === 1 ? true : false} name="id_gender" />
                    &nbsp;M
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={onChange} value="0" defaultChecked={state.id_gender === 0 ? true : false} name="id_gender" />
                    &nbsp;Mme
                  </label>
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="firstname">
                  Nom&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={state.firstname}
                    onChange={onChange}
                    required
                    readOnly
                    className="input readonly"
                    placeholder="Votre Nom"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="lastname">
                  Prénom&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    value={state.lastname}
                    onChange={onChange}
                    required
                    readOnly
                    className="input readOnly"
                    placeholder="Votre Prénom"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="email">
                  E-mail&nbsp;:
                </label>
                <div className="control">
                  <input
                    onChange={onChange}
                    type="email"
                    id="email"
                    name="email"
                    readOnly
                    autoComplete="email"
                    value={state.email}
                    className="input readOnly"
                    placeholder="Votre E-mail"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="mdp">
                  Mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="password"
                    id="mdp"
                    name="mdp"
                    onChange={onChange}
                    className="input"
                    autoComplete="current-password"
                    placeholder="votre mot de passe"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="Nmdp">
                  Nouveau mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="password"
                    id="Nmdp"
                    name="Nmdp"
                    autoComplete="new-password"
                    onChange={onChange}
                    className={`input `}
                    placeholder="Votre nouveau mot de passe"
                  />
                </div>
              </div>

              <div className="field">
                <label className="label" htmlFor="CNmdp">
                  Confirmer le nouveau mot de passe&nbsp;:
                </label>
                <div className="control">
                  <input
                    type="password"
                    id="CNmdp"
                    autoComplete="new-password"
                    name="CNmdp"
                    onChange={onChange}
                    className={`input ${state.Nmdp.length >= 1 && state.Nmdp !== state.CNmdp ? "is-danger" : ""}`}
                    placeholder="Confirmer nouveau mot de passe"
                  />
                </div>
              </div>

              <div className="columns has-text-right" style={{ marginTop: "2rem" }}>
                <div className="column has-text-left">
                  <button className="button is-primary" onClick={event => downloadTxtFile(event)} onKeyPress={() => {}} tabIndex={0}>
                    {" "}
                    Télécharger mes données personnelles{" "}
                  </button>
                </div>
                <div className="column">
                  <input
                    type="submit"
                    value="Enregistrer"
                    disabled={!(state.Nmdp.length == 0 || (state.Nmdp.length >= 1 && state.CNmdp === state.Nmdp))}
                    className={`button is-primary is-large ${loading ? "is-loading" : ""}`}
                  />
                </div>
              </div>
            </form>
            {error ? <div className="notification is-danger mt-4">{error}</div> : null}
            {success ? <div className="notification is-success mt-4">Information mise à jour avec succès.</div> : ""}
          </div>
        </div>
      </ul>
    </>
  )
}

MesDonneesPerso.propTypes = {
  donneesPersoATelecharger: PropTypes.object,
}

export default MesDonneesPerso
