import React from "react"
import MesAdresses from "./../components/profile/adresses"
import DonnesPerso from "./../components/profile/donneesPerso"
import Commandes from "./../components/profile/commandes"
import { updateInfos } from "./../actions/auth"

import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-spinner"
import SEO from "./../components/seo"
import { useDispatch, useSelector } from "react-redux"
import Reseller from "../components/profile/reseller"

export const Profile = () => {
  const tab = window.location.hash === "" ? "commandes" : window.location.hash.replace("#", "")
  const identifiants = useSelector(s => s.identifiants)
  const paiement = useSelector(s => s.paiement)
  const dispatch = useDispatch()

  if (!identifiants.identifiants || !identifiants.identifiants.firstname || !identifiants.identifiants.lastname) {
    if (typeof window !== "undefined") {
      if (typeof window !== "undefined") {
        navigate(`/connexion?redirect=/apps/mon-compte`)
      }
      return null
    }
    return null
  }

  if (new Date() / 1000 - identifiants.lastUpdate > 30) {
    // update max toutes les 30 sec
    dispatch(updateInfos({}, paiement.idOrder))
  }

  return (
    <>
      <SEO title="Mon compte" description="Mon compte" />

      <h1 style={{ marginTop: "6rem" }}>Mon compte</h1>
      <div className="columns">
        <div className="column is-3">
          <aside className="menu">
            <ul className="menu-list">
              <li>
                <a href="#commandes" className={tab == "commandes" ? "is-active" : null}>
                  Mes commandes
                </a>
              </li>
              <li>
                <a href="#adresses" className={tab == "adresses" ? "is-active" : null}>
                  Mes adresses
                </a>
              </li>
              <li>
                <a href="#donneesPerso" className={tab == "donneesPerso" ? "is-active" : null}>
                  Mes données personnelles
                </a>
              </li>
              {[4, 32, 27].includes(parseInt(identifiants.identifiants.id_customer)) && (
                <li>
                  <a href="#revendeur" className={tab == "revendeur" ? "is-active" : null}>
                    Revendeur
                  </a>
                </li>
              )}
            </ul>
          </aside>
        </div>
        <div className="column" style={{ paddingBottom: "3rem" }}>
          {tab == "commandes" ? <Commandes commandes={identifiants.identifiants.orders} /> : ""}
          {tab == "adresses" ? <MesAdresses profile={identifiants} /> : ""}
          {tab == "donneesPerso" ? <DonnesPerso /> : ""}
          {tab == "revendeur" && [4, 32, 27].includes(parseInt(identifiants.identifiants.id_customer)) ? <Reseller /> : ""}
          {tab == "adresses" ? (
            <div className="has-text-centered">
              <Link to="/apps/mon-compte/ajout-adresse/0" className="button is-primary">
                + Créer une nouvelle adresse
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

Profile.propTypes = {}

export default Profile
