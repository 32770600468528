import React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import { setDeliveryAddress, setInvoiceAddress } from "./../actions/"

class ChoixAdresses extends React.Component {
  constructor(props) {
    super(props)

    this.props.dispatch(setDeliveryAddress(this.props.customer.addresses[0].id_address))
    this.props.dispatch(setInvoiceAddress(this.props.customer.addresses.find(e => (e.alias = "Adresse entreprise")).id_address))

    this.state = {
      isChecked: true,
    }
  }

  handleUpdateLivraison = event => {
    this.props.dispatch(setDeliveryAddress(event.target.value))
  }

  handleChecked = event => {
    this.setState({ isChecked: event.target.checked })
    if (event.target.checked) {
      this.props.dispatch(setInvoiceAddress(this.props.commande.deliveryAddress))
    }
  }

  handleUpdate = id_address => {
    if (typeof window !== "undefined") {
      navigate(`/apps/mon-compte/ajout-adresse/` + id_address + `#commande`)
    }
  }

  render() {
    const delivery_address = this.props.customer.addresses.find(u => u.id_address === this.props.commande.deliveryAddress)
    const invoice_address = this.props.customer.addresses.find(u => u.id_address === this.props.commande.invoiceAddress)
    return (
      <>
        <div className="columns">
          <div className="column is-6 is-multiline">
            <div>
              Choisissez une adresse de livraison :{" "}
              <select
                id="adrLivraison"
                value={this.props.commande.deliveryAddress === null ? "" : this.props.commande.deliveryAddress}
                onChange={this.handleUpdateLivraison}
                onBlur={() => {}}
              >
                {this.props.customer.addresses.map((adresse, index) => (
                  <option key={index} value={adresse.id_address} data-country={adresse.id_country}>
                    {adresse.alias}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="column is-6">
            {this.state.isChecked ? (
              ""
            ) : (
              <div>
                Choisissez une adresse de facturation :{" "}
                <select id="adrFacturation" onChange={this.handleUpdateFacturation} value={this.props.commande.invoiceAddress} onBlur={() => {}}>
                  {this.props.customer.addresses
                    .filter(e => e.id_country === delivery_address.id_country)
                    .map((adresse, index) => (
                      <option key={index} value={adresse.id_address} data-country={adresse.id_country}>
                        {adresse.alias}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
        </div>
        {delivery_address && invoice_address ? (
          <div className="columns">
            <div className="column is-6">
              <h3>Votre adresse de livraison</h3>
              <div className="box-adresses pb-3">
                <div>
                  <h4 className="adresses_title">{delivery_address.alias}</h4>
                  <div style={{ marginTop: "1.5rem" }}>
                    {this.props.customer.firstname} {this.props.customer.lastname}
                  </div>
                  <div>{delivery_address.societe}</div>
                  <div>{delivery_address.address1}</div>
                  <div>
                    {delivery_address.postcode} {delivery_address.city}
                  </div>
                  <div>{delivery_address.name_country}</div>
                  <div>{delivery_address.phone_mobile}</div>
                  {delivery_address.alias !== "Adresse entreprise" ? (
                    <div style={{ marginTop: "1.5rem" }}>
                      <button className="button button-adresse is-primary is-small" onClick={() => this.handleUpdate(delivery_address.id_address)}>
                        Mettre à jour
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="column is-6">
              <h3>Votre adresse de facturation</h3>
              <div className="box-adresses pb-3">
                <ul>
                  <div className="adresses_title">{invoice_address.alias}</div>
                  <div style={{ marginTop: "1.5rem" }}>
                    {this.props.customer.firstname} {this.props.customer.lastname}
                  </div>
                  <div>{invoice_address.societe}</div>
                  <div>{invoice_address.address1}</div>
                  <div>
                    {invoice_address.postcode} {invoice_address.city}
                  </div>
                  <div>{invoice_address.name_country}</div>
                  <div>{invoice_address.phone_mobile}</div>
                </ul>
              </div>
            </div>
          </div>
        ) : null}

        <Link to="/apps/mon-compte/ajout-adresse/0#commande" className="button is-primary">
          + Créer une nouvelle adresse
        </Link>
      </>
    )
  }
}

ChoixAdresses.propTypes = {
  dispatch: PropTypes.func,
  commande: PropTypes.object,
  customer: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    commande: state.cart,
    customer: state.identifiants.identifiants,
  }
}

export default connect(mapStateToProps)(ChoixAdresses)
