import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { Link } from "gatsby-plugin-spinner"

import { connect } from "react-redux"
import ChoixAdresses from "./../components/choixAdresses"
import ChoixModeLivraison from "./../components/choixModeLivraison"

import ProductsPanier from "./../components/productsPanier"

import Cb from "./../components/paiement/cb"
import Paypal from "./../components/paiement/paypal"
import Virement from "./../components/paiement/virement"
import Cheque from "./../components/paiement/cheque"
import Devis from "./../components/paiement/devis"

import RecapCheque from "./../components/recapPaiement/recapCheque"
import RecapVirement from "./../components/recapPaiement/recapVirement"
import RecapPaypal from "./../components/recapPaiement/recapPaypal"

import FormAdresse from "./../components/profile/formAdresse"

import { passeCommande } from "./../actions/passCommande"

import SEO from "./../components/seo"

class Commande extends React.Component {
  constructor(props) {
    super(props)

    const frais_port = 15
    const mincommande = 450

    const total = this.props.cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0) + frais_port

    this.state = {
      paiement: false,
      data: "",
      modePaiement: "",
      total: total.toFixed(2),
      min_commande: mincommande,
      productNotExistInRedux: 0,
      ModeLivraisonCout: frais_port,
      boolMinCommande: this.testMinCommande(total, mincommande),
      CountryAdressLivraison:
        props.identifiants.identifiants !== null && props.identifiants.identifiants.addresses.length >= 1
          ? props.identifiants.identifiants.addresses[0].id_country
          : 8,
    }
  }

  testMinCommande(total, mincommande) {
    var boolOnlySample = false
    this.props.cart.products.forEach(produit => (produit.type == "echantillon" || produit.echantillon ? (boolOnlySample = true) : (boolOnlySample = false)))

    if (boolOnlySample == true) {
      return true
    } else {
      if (total >= mincommande) {
        return true //minimum de commande atteint
      } else {
        return false //minimum de commande non atteint
      }
    }
  }

  handleChecked = () => {
    this.setState({ paiement: !this.state.paiement })
    if (!this.state.paiement) {
      if (typeof window !== "undefined") {
        navigate("/apps/commande#paiement")
      }
    } else {
      if (typeof window !== "undefined") {
        navigate("/apps/commande#modeLivraison")
      }
    }
  }

  passePaiement = type => {
    this.setState({ modePaiement: type })

    this.props.dispatch(
      passeCommande(
        this.props.cart.products,
        type,
        this.props.cart.invoiceAddress,
        this.props.cart.deliveryAddress,
        this.props.cart.modeLivraison,
        this.props.fraisPort
      )
    )
  }

  removeProduct = total => {
    this.setState({ total: total })
  }

  updateQtyProduct = total => {
    this.setState({ total: total.toFixed(2) })
  }

  productNotExistInRedux = exist => {
    this.setState({ productNotExistInRedux: exist })
  }

  myUrlRedirect = () => {
    if (typeof window !== "undefined") {
      navigate(`/connexion?redirect=/apps/commande/`)
    }
    return null
  }

  render() {
    if (
      !this.props.identifiants.identifiants ||
      !this.props.identifiants.identifiants.firstname ||
      (!this.props.identifiants.identifiants.lastname && this.props.location.pathname !== `/`)
    ) {
      this.myUrlRedirect()
      return null
    }
    const delivery_address = this.props.identifiants.identifiants.addresses.find(e => e.id_address == this.props.cart.deliveryAddress)
    const delivery_country = delivery_address ? parseInt(delivery_address.id_country) : 8
    return (
      <>
        <SEO title="Commander" description="Commander" />
        {this.props.cart.products.length > 0 ? (
          this.state.boolMinCommande ? (
            <div className="container_margin commande" style={{ paddingBottom: "3rem" }}>
              <h2>1. Adresses :</h2>
              {this.props.identifiants.identifiants.addresses.length >= 1 ? <ChoixAdresses /> : <FormAdresse propComponent={this.props} id_address="0" />}
              <h2>2. Mode de livraison :</h2>

              {this.props.identifiants.identifiants.addresses.length >= 1 ? <ChoixModeLivraison /> : ""}
              <h2>3. Récapitulatif de votre commande :</h2>
              <ProductsPanier
                removeProduct={this.removeProduct}
                updateQtyProduct={this.updateQtyProduct}
                showQuantite={false}
                productNotExistInRedux={this.productNotExistInRedux}
                fraisLivraison={parseFloat(this.state.ModeLivraisonCout)}
                boolMinCommande={this.state.boolMinCommande}
                min_commande={this.state.min_commande}
              />
              {this.state.productNotExistInRedux === 1 ? (
                <div className="has-text-centered notification is-warning">
                  <p>Pour pouvoir commander il faut supprimer les produits avec la mention erreur</p>
                </div>
              ) : (
                <>
                  {this.props.identifiants.identifiants.addresses.length >= 1 ? (
                    <table className="tableLivraison" id="modeLivraison" style={{ display: "inline-block", boxShadow: "none" }}>
                      <tbody>
                        <tr>
                          <td>
                            <div
                              onClick={() => {
                                this.handleChecked()
                              }}
                              onKeyPress={() => {}}
                              role="checkbox"
                              aria-checked={this.state.paiement}
                              className={`disableOutline`}
                              tabIndex={0}
                            >
                              <label htmlFor="accept_cgv">
                                <span className={`modern_chk ${this.state.paiement ? "checked" : ""}`} id="accept_cgv" /> J’ai lu les conditions générales de
                                vente et j’y adhère sans réserve.
                              </label>
                              <Link to="/conditions-vente"> (Lire les Conditions générales de vente)</Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    ""
                  )}

                  <h2 id="paiement">4. {delivery_country === 8 ? "Moyen de paiement ou devis" : "Demande de devis"} :</h2>
                  {this.state.paiement ? (
                    delivery_country === 8 ? (
                      <div id="paiement" className="columns has-text-centered">
                        <div className="column">
                          <Cb />
                        </div>
                        <div className="column">
                          <Paypal
                            total={parseFloat(
                              this.props.cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0) + this.props.cart.fraisPort
                            )}
                            passePaiement={this.passePaiement}
                            identifiants={this.props.identifiants}
                          />
                        </div>
                        <div className="column">
                          <Virement />
                        </div>
                        <div className="column">
                          <Cheque />
                        </div>
                        <div className="column">
                          <Devis />
                        </div>
                      </div>
                    ) : (
                      <div id="paiement">
                        <Devis />
                      </div>
                    )
                  ) : this.props.identifiants.identifiants.addresses.length >= 1 ? (
                    <p className="warning">Veuillez accepter les conditions générales de vente</p>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="container_margin">
              <div className="has-text-centered notification is-warning">
                <p>Vous êtes en dessous du minimum de commande de {this.state.min_commande / 1.2} € (HT hors frais de port et emballage)</p>
                <p>Veuillez rajouter des produits pour atteindre le minimum de commande</p>
              </div>

              <Link className="button is-pulled-right" to="/cadres" style={{ marginTop: "1rem" }}>
                Continuer mes achats
              </Link>
            </div>
          )
        ) : this.state.modePaiement == "Chèque" ? (
          <RecapCheque />
        ) : this.state.modePaiement == "Virement" ? (
          <RecapVirement dataRecap={this.props.cart.recapPaiement} />
        ) : this.state.modePaiement == "PayPal" ? (
          <RecapPaypal dataRecap={this.props.cart.recapPaiement} />
        ) : (
          ""
        )}
      </>
    )
  }
}

Commande.propTypes = {
  dispatch: PropTypes.func,
  identifiants: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  cart: PropTypes.object,
  fraisPort: PropTypes.number,
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
    loading: state.loading.loading,
    fraisPort: state.cart.fraisPort,
  }
}

export default connect(mapStateToProps)(Commande)
