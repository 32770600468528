import React from "react"
import {Link} from "gatsby-plugin-spinner"
//import PropTypes from "prop-types"

class Cheque extends React.Component {
  constructor(props) {
    super(props)
  }
  /* handleSelected = () => {
    this.props.passePaiement("Chèque")
  }*/

  render() {
    return (
      <Link
        to="/apps/commande/Chèque"
        className="tableLivraison modePaiement button is-primary is-large"
      >
        Chèque
      </Link>
    )
  }
}

Cheque.propTypes = {
  /*passePaiement: PropTypes.function*/
}

export default Cheque
