import React from "react"
import { Link, navigate } from "gatsby"
import PropTypes from "prop-types";

import { connect } from "react-redux"
import {updateMDP} from "./../../actions/auth"

class ModifMDP extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
        Nmdp: "",
        CNmdp: "",
        isEqual1: "",
        isEqual2: "",
        isDisabled: false,
        isValide: true
    }

  }

  handleSubmit = (event) => {
    event.preventDefault();

    const data = {};
    data.Nmdp = this.state.Nmdp;
    data.CNmdp = this.state.CNmdp;
    data.reset_token = this.props.reset_token;
    data.token = this.props.token;

    this.props.dispatch(updateMDP(this.props.id_customer, data))
    
  }


  onChange = (event) => {
    this.setState({[event.target.name]: event.target.value})

    if(event.target.name === "Nmdp"){
        if((event.target.value).length > 0){
          if(this.state.CNmdp === ""){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: true})
          }else if(event.target.value === this.state.CNmdp){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: false})
          }else{
            this.setState({isEqual1: "is-danger",isEqual2: "",isDisabled: true})
          }
        }else{
          if(this.state.CNmdp === ""){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: false})
          }else{          
            this.setState({isEqual1: "is-danger",isEqual2: "",isDisabled: true})
          }
        }
      }else if(event.target.name === "CNmdp"){
        if((event.target.value).length > 0){
          if(this.state.Nmdp === ""){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: true})
          }else if(event.target.value === this.state.Nmdp){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: false})
          }else{
            this.setState({isEqual1: "",isEqual2: "is-danger",isDisabled: true})
          }
        }else{
          if(this.state.Nmdp === ""){
            this.setState({isEqual1: "",isEqual2: "",isDisabled: false})
          }else{
            this.setState({isEqual1: "",isEqual2: "is-danger",isDisabled: true})
          }
        }
      }

  }

  componentDidMount = () => {
    var url = process.env.GATSBY_API_URL+"/index.php?controller=authentification";
    fetch(url, {
    method: 'POST',
    body: JSON.stringify({
        action: "verif_reset_token",
        id_customer: this.props.id_customer,
        reset_token: this.props.reset_token,
        token: this.props.token
        }),
    }).then(response =>  response.json())
    .then(result => {
        if(!result.id_customer){
            this.setState({isValide: false})
        }
    }).catch(function(error) {
        console.log("Request failed", error)
    })


  }

  render() {   
       

    if (
        this.props.identifiants.identifiants &&
        this.props.identifiants.identifiants.firstname &&
        this.props.identifiants.identifiants.lastname
      ) {
        if (typeof window !== 'undefined') {
          navigate(`/cadres`)
        }
      }


    return(
        <>
            {this.state.isValide ?
                <div className="columns is-multiline is-centered" style={{marginTop:'5rem'}}>
                    <div className="column is-5">
                    <form  method="post"  onSubmit={this.handleSubmit} >

                        <div className="field">
                        <label className="label" htmlFor="Nmdp">
                        Nouveau mot de passe&nbsp;:
                        </label>
                        <div className="control">
                            <input
                            type="password"
                            id="Nmdp"
                            name="Nmdp"
                            onChange={this.onChange}
                            required
                            className={`input ${this.state.isEqual1}`}
                            placeholder="Votre nouveau mot de passe"
                            />
                        </div>
                        </div>

                                        
                        <div className="field">
                        <label className="label" htmlFor="CNmdp">
                        Confirmer le nouveau mot de passe&nbsp;:
                        </label>
                        <div className="control">
                            <input
                            type="password"
                            id="CNmdp"
                            name="CNmdp"
                            onChange={this.onChange}
                            required
                            className={`input ${this.state.isEqual2}`}
                            placeholder="Confirmer nouveau mot de passe"
                            />
                        </div>
                        </div>

                        <div className="field is-grouped" style={{ marginTop: "2rem" }}>
                        <div className="control">
                            <input type="submit" className="button" value="Changer le mot de passe" disabled={this.state.isDisabled}/>
                        </div>
                        </div>

                    </form>
                    </div>
                </div>
            :
                            
                <div className="notification is-danger">                  
                    <p>La demande de réinitialisation de mot de passe a expiré. Veuillez recommencer.<Link to="apps/mon-compte/init-mdp">Ici...</Link></p>
                </div>
            }
        </>
    )
  }

}


ModifMDP.propTypes = {
    identifiants: PropTypes.object,
    id_customer: PropTypes.string,
    reset_token: PropTypes.string,
    token: PropTypes.string,
    dispatch: PropTypes.func
}
  
  
function mapStateToProps(state) {
    return {
      identifiants: state.identifiants,
    }
}
  
export default connect(mapStateToProps)(ModifMDP)
