import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Obfuscate from "react-obfuscate"

class RecapVirement extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <>
        <div style={{ marginTop: "9rem" }}>
          <p>
            <h2>Votre commande sur france-cadre.fr a bien été enregistrée.</h2>
            <br />
            <h3>Veuillez nous envoyer un virement bancaire :</h3>
            <br />
            <br />
            <table>
              <tr>
                <td>Montant</td>
                <td>
                  {/* {this.props.dataRecap.total} € */}
                  {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(this.props.dataRecap.total))}
                </td>
              </tr>
              <tr>
                <td>à l’ordre de</td>
                <td>{this.props.dataRecap.nameDestinationPaiement}</td>
              </tr>
              <tr>
                <td>Veuillez inclure ces informations</td>
                <td>{this.props.dataRecap.ibanBic}</td>
              </tr>
              <tr>
                <td>à cette banque</td>
                <td>{this.props.dataRecap.addressDestinationPaiement}</td>
              </tr>
            </table>
            <br />
            <br />
            Veuillez préciser <b>impérativement</b> votre <b>numéro de commande</b> <span>{this.props.dataRecap.id_order}</span> dans la description du
            virement. Nous vous avons également envoyé ces informations par e-mail accompagné du RIB de l’entreprise.
            <br />
            <br />
            <span>Votre commande sera traitée par nos services après réception de votre virement dans les plus brefs délais.</span>
            <br />
          </p>
          <p>
            Pour toutes questions, vous pouvez joindre notre service client : <br />
            par téléphone au : <b>03 89 06 00 93</b> choix 01 (du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h) <br />
            ou envoyer un mail à : <Obfuscate email="contact@producadre.fr" /> (une réponse vous sera faite sous 24h)
          </p>
          <p> En vous remerciant pour votre confiance.</p>
        </div>
      </>
    )
  }
}

RecapVirement.propTypes = {
  dataRecap: PropTypes.object,
}

//export default RecapVirement;
function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(RecapVirement)
