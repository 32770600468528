import React from "react"
import PropTypes from "prop-types"

import { navigate } from "gatsby"

import { connect } from "react-redux"
import { addNewAdress, deleteAddress } from "./../../actions/auth"
import FormAdresse from "./formAdresse"

class MesAdresses extends React.Component {
  constructor(props) {
    super(props)

    const lastname = this.props.profile.identifiants.lastname
    const firstname = this.props.profile.identifiants.firstname

    this.state = {
      alias: null,
      firstname: firstname,
      lastname: lastname,
      societe: null,
      ntva: null,
      adresse: null,
      complementAdresse: null,
      codePostal: null,
      ville: null,
      pays: 8,
      telephone: null,
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleUploadfile = event => {
    event.preventDefault()

    const data = {}
    data.alias = this.state.alias
    data.firstname = this.state.firstname
    data.lastname = this.state.lastname
    data.societe = this.state.societe
    data.ntva = this.state.ntva
    data.adresse = this.state.adresse
    data.complementAdresse = this.state.complementAdresse
    data.codePostal = this.state.codePostal
    data.ville = this.state.ville
    data.pays = this.state.pays
    data.telephone = this.state.telephone

    this.props.dispatch(addNewAdress(data))
  }

  handleClick = id_address => {
    this.props.dispatch(deleteAddress(id_address))
  }

  handleUpdate = id_address => {
    if (typeof window !== "undefined") {
      navigate(`/apps/mon-compte/ajout-adresse/` + id_address)
    }
  }

  render() {
    return (
      <>
        {this.props.profile.identifiants.addresses.map((adresse, index) =>
          adresse.id_address !== null ? (
            <div className="column is-6" key={index}>
              <div className="box-adresses">
                <h4 className="adresses_title">{adresse.alias}</h4>
                <div style={{ marginTop: "1.5rem" }} className="has-text-weight-bold">
                  {this.props.profile.identifiants.firstname} {this.props.profile.identifiants.lastname}
                </div>
                <div>
                  {adresse.address1} {adresse.address2}
                </div>
                <div>
                  {adresse.postcode} {adresse.city}
                </div>
                <div>{adresse.name_country}</div>
                <div>{adresse.phone_mobile}</div>
                {adresse.alias !== "Adresse entreprise" ? (
                  <div style={{ marginTop: "1.5rem" }}>
                    <button className="button button-adresse is-primary is-small" onClick={() => this.handleUpdate(adresse.id_address)}>
                      Mettre à jour
                    </button>{" "}
                    <button
                      className="button button-adresse is-primary is-small"
                      onClick={() => this.handleClick(adresse.id_address)}
                      style={{ marginLeft: "1rem" }}
                    >
                      Supprimer
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <FormAdresse propComponent={this.props} id_address="0" key={index} />
          )
        )}
      </>
    )
  }
}

MesAdresses.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(MesAdresses)
