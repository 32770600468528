import React from "react"
import { Router } from "@reach/router"

import Layout from "../components/layout"

import Profile from "../containers/profile"
import AjouterAdresse from "../components/ajouterAdresse"
import OrderDetails from "../containers/orderDetails"

import InitMDP from "../components/mot_de_passe/initMDP"
import ModifMDP from "../components/mot_de_passe/modifMDP"

import Commande from "../containers/commande"
import RecapPaiement from "../components/paiement/recapPaiement"

const App = () => (
  <Layout banner={false}>
        <Router>

          <Profile path="/apps/mon-compte/" />
          <AjouterAdresse path="/apps/mon-compte/ajout-adresse/:adresseSelected" />
          <OrderDetails path="/apps/mon-compte/commande/:id_order" />

          <InitMDP path="/apps/mon-compte/init-mdp" />
          <ModifMDP path="/apps/mon-compte/init-mdp/:token/:id_customer/:reset_token" />

          <Commande path="/apps/commande/" />
          <RecapPaiement path="/apps/commande/:module" />

        </Router>

  </Layout>
)

export default App
