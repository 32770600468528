import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { setIdOrder } from "../../actions"

class Cb extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hmac: "",
      contexte_commande: "",
      id_order: "",
      reference: "",
      lang: "",
      version: "",
      total: "",
      tpe: "",
      email: "",
      societe: "",
      description: "",
      retour_ok: "",
      retour_err: "",
      server: "",
      date: "",
    }
    this.formRef = React.createRef()
  }

  onSubmit = event => {
    if (this.state.hmac) {
      return true
    }

    event.preventDefault()
    const total = this.props.products.reduce((acc, product) => acc + product.qty * product.price, 0).toFixed(2)
    const shipping = this.props.fraisPort
    this.setState({ total })
    var url = process.env.GATSBY_API_URL + "/index.php?controller=myorder"
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "cb",
        adresseLivraison: this.props.livraison,
        adresseFacturation: this.props.facturation,
        customer: this.props.customer,
        total,
        shipping,
        email: this.props.identifiants.email,
        hash: this.props.identifiants.hash,
        site: process.env.SITE,
        origin: window.location.origin,
        id_order: this.props.id_order || "",
        id_site: process.env.SITE,
        modelivraison: this.props.modeLivraison,
        panier: this.props.products,
      }),
    })
      .then(response => response.json())
      .then(r => {
        let result = r.hmac
        this.setState({
          hmac: result.mac,
          date: result.date,
          contexte_commande: result.contexte_commande,
          id_order: result.id_order,
          reference: result.reference,
          tpe: result.tpe,
          email: result.email,
          societe: result.societe,
          description: result.description,
          retour_ok: result.url_retour_ok,
          retour_err: result.url_retour_err,
          version: result.version,
          lang: result.lang,
          server: result.server,
          shipping,
        })
        this.props.dispatch(setIdOrder(result.id_order))
        this.formRef?.current?.dispatchEvent(new Event("submit"))
      })
      .catch(error => {
        console.log("Request failed", error)
      })
  }

  render() {
    return (
      <div className="tableLivraison modePaiement">
        <form action={this.state.server} method="post" ref={this.formRef} onSubmit={this.onSubmit}>
          <input type="hidden" name="MAC" id="MAC" value={this.state.hmac} />
          <input type="hidden" name="TPE" id="TPE" value={this.state.tpe} />
          <input id="contexte_commande" type="hidden" name="contexte_commande" value={this.state.contexte_commande} />
          <input type="hidden" name="date" id="date" value={this.state.date} />
          <input type="hidden" name="dateech1" value="" />
          <input type="hidden" name="dateech2" value="" />
          <input type="hidden" name="dateech3" value="" />
          <input type="hidden" name="dateech4" value="" />
          <input
            type="hidden"
            name="montant"
            id="montant"
            value={`${(parseFloat(this.state.total) + parseFloat(this.state.shipping)).toFixed(2)}EUR`}
          />
          <input type="hidden" name="montantech1" value="" />
          <input type="hidden" name="montantech2" value="" />
          <input type="hidden" name="montantech3" value="" />
          <input type="hidden" name="montantech4" value="" />
          <input type="hidden" name="nbrech" value="" />
          <input id="reference" type="hidden" name="reference" value={this.state.reference} />
          <input type="hidden" name="lgue" id="lgue" value={this.state.lang} />
          <input type="hidden" name="mail" id="mail" value={this.state.email} />
          <input type="hidden" name="societe" id="societe" value={this.state.societe} />
          <input type="hidden" name="texte-libre" id="texte-libre" value={this.state.description} />
          <input type="hidden" name="url_retour_ok" id="url_retour_ok" value={this.state.retour_ok} />
          <input type="hidden" name="url_retour_err" id="url_retour_err" value={this.state.retour_err} />
          <input type="hidden" name="version" id="version" value={this.state.version} />
          <button className="buttonSubmit button is-primary is-large" type="submit">
            Carte bancaire
          </button>
        </form>
      </div>
    )
  }
}

Cb.propTypes = {
  customer: PropTypes.string,
  dispatch: PropTypes.func,
  facturation: PropTypes.string,
  identifiants: PropTypes.object,
  fraisPort: PropTypes.number,
  id_order: PropTypes.string,
  livraison: PropTypes.string,
  modeLivraison: PropTypes.string,
  products: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    id_order: state.paiement.idOrder,
    livraison: state.cart.deliveryAddress,
    facturation: state.cart.invoiceAddress,
    customer: state.identifiants.identifiants.id_customer,
    identifiants: state.identifiants.identifiants,
    modeLivraison: state.cart.modeLivraison,
    products: state.cart.products,
    fraisPort: state.cart.fraisPort,
  }
}

export default connect(mapStateToProps)(Cb)
