import React from "react"
import {Link} from "gatsby-plugin-spinner"

class Devis extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Link
        to="/apps/commande/Devis"
        className="tableLivraison modePaiement button is-primary is-large"
      >
        Devis
      </Link>
    )
  }
}

export default Devis
