import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"
import { v4 as uuid } from "uuid"
import { addProduct } from "../actions/index"
import { useDispatch, useSelector } from "react-redux"

const OrderDetails = props => {
  const identifiants = useSelector(s => s.identifiants.identifiants)

  const order = identifiants.orders.filter(u => u.id_order === props.id_order)[0]
  const url = process.env.GATSBY_API_URL + "/index.php?controller=pdf-invoice&id_order="

  const dispatch = useDispatch()
  const handleSelected = (products, event) => {
    event.preventDefault()

    products.map(p => {
      // Dans l'idéal il faudrait vérifier que le produit existe toujours
      dispatch(
        addProduct({
          _id: `product_${uuid()}`,
          ...JSON.parse(p.json.replace(/(\r\n|\n|\r)/gm, "")),
        })
      )
    })

    if (typeof window !== "undefined") {
      navigate(`/apps/commande`)
    }
  }
  const SousTotal = order.products.reduce((acc, product) => (acc += parseFloat(product.total_price_tax_incl)), 0)
  return (
    <>
      <div className="container_margin">
        <Link to="/">Accueil</Link> / <Link to="/apps/mon-compte/">Votre Compte</Link> / <h3>Détails de la commande</h3>
        <div className="card" style={{ padding: "10px", margin: "20px 0px 0px 0px" }}>
          <p>
            <b>
              Commande n°{order.reference} du {order.date_add}
            </b>
          </p>
        </div>
        <div className="card" style={{ padding: "10px", margin: "20px 0px 0px 0px" }}>
          <p>
            <b>Transporteur</b> : {order.name_carrier}
          </p>
          <p>
            <b>Moyen de paiement ou devis</b> : {order.payment}
          </p>
          {order.valid == 1 ? (
            <a href={`${url}${order.id_order}&secure_key=${order.secure_key}`}>
              Téléchargez votre {order.payment == "Devis" ? "devis" : "facture"} au format PDF
            </a>
          ) : (
            ""
          )}

          {order.order_history[0].id_order_state == 2104 ? (
            <p>
              <button
                /*  disabled={this.props.disabled ? "true" : ""} */
                className="button is-medium is-success"
                style={{ marginTop: "1rem" }}
                onClick={() => handleSelected(order.products, event)}
              >
                <FontAwesomeIcon icon={faCheck} />
                &nbsp;&nbsp;Accepter le devis
              </button>
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="card" style={{ padding: "10px", margin: "20px 0px 0px 0px" }}>
          <h3>État de votre commande</h3>
          <table className="tablePanier">
            <thead>
              <tr>
                <th>Date</th>
                <th>État</th>
              </tr>
            </thead>
            <tbody>
              {order.order_history.map((history, index) => (
                <tr key={index}>
                  <td>{history.date_add}</td>
                  <td>
                    <span style={{ background: history.color, color: "#fff" }}>{history.name}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="columns is-multiline" style={{ marginTop: "1.5rem" }}>
          <div className="column is-6">
            <h4 className="adresses_intitle">Adresse de livraison :</h4>
            <div className="box-adresses">
              <h4 className="adresses_title">{order.alias_pad}</h4>
              <div style={{ marginTop: "1.5rem" }} className="has-text-weight-bold">
                {order.firstname_pad} {order.lastname_pad}
              </div>
              <div>
                {order.address1_pad} {order.address2_pad}
              </div>
              <div>
                {order.postcode_pad} {order.city_pad}
              </div>
              <div>{order.country_pad}</div>
              <div style={{ marginTop: "1.5rem" }}></div>
            </div>
          </div>

          <div className="column is-6">
            <h4 className="adresses_intitle">Adresse de facturation :</h4>
            <div className="box-adresses">
              <h4 className="adresses_title">{order.alias_pai}</h4>
              <div style={{ marginTop: "1.5rem" }} className="has-text-weight-bold">
                {order.firstname_pai} {order.lastname_pai}
              </div>
              <div>
                {order.address1_pai} {order.address2_pai}
              </div>
              <div>
                {order.postcode_pai} {order.city_pai}
              </div>
              <div>{order.country_pai}</div>
              <div style={{ marginTop: "1.5rem" }}></div>
            </div>
          </div>
        </div>
        <div className="card" style={{ padding: "10px", margin: "20px 0px 0px 0px" }}>
          <h3>Produits de votre commande </h3>
          <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB", margin: 0 }}>
            <div className="panier header column is-6">Produit</div>
            <div style={{ borderLeft: "0" }} className="panier header column is-2">
              Quantité
            </div>
            <div style={{ borderLeft: "0" }} className="panier header column is-2">
              Prix unitaire
            </div>

            <div style={{ borderLeft: "0" }} className="panier header column is-2">
              Total (TTC)
            </div>
          </div>

          {order.products.map((product, index) => (
            <div key={index} className="columns panier line" style={{ margin: 0 }}>
              <div className="column is-6" dangerouslySetInnerHTML={{ __html: product.product_name }} />
              <div className="column is-2">
                <span className="is-hidden-desktop">Quantité :&nbsp;</span>
                {product.product_quantity}{" "}
              </div>
              <div className="column is-2">
                <span className="is-hidden-desktop">Prix unitaire :&nbsp;</span>
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(product.product_price)}
              </div>
              <div className="column is-2">
                <span className="is-hidden-desktop">Total :&nbsp;</span>
                {new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(product.product_price * product.product_quantity)}
              </div>
            </div>
          ))}
          <div className="columns">
            <div className="column is-offset-6 is-6">
              <table>
                <tbody>
                  <tr>
                    <td>Sous-total</td>
                    <td>
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(parseFloat(SousTotal))}
                    </td>
                  </tr>
                  <tr>
                    <td>Frais de livraison</td>
                    <td>
                      {" "}
                      {order.total_shipping == 0
                        ? "Gratuit"
                        : new Intl.NumberFormat("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          }).format(parseFloat(order.total_shipping))}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>
                      {new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: "EUR",
                      }).format(parseFloat(order.total_paid + order.total_shipping))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card" style={{ padding: "10px", margin: "20px 0px 0px 0px" }}>
          <h3>Suivre votre commande pas à pas</h3>
          <div className="columns has-text-weight-bold has-text-centered is-hidden-touch" style={{ background: "#FBFBFB", margin: 0 }}>
            <div className="panier header column is-2">Date</div>
            <div style={{ borderLeft: "0" }} className="panier header column is-3">
              Transporteur
            </div>
            <div style={{ borderLeft: "0" }} className="panier header column is-2">
              Poids
            </div>

            <div style={{ borderLeft: "0" }} className="panier header column is-2">
              Frais d&apos;expédition
            </div>
            <div style={{ borderLeft: "0" }} className="panier header column is-3">
              Numéro de suivi
            </div>
          </div>
          <div className="columns" style={{ margin: 0 }}>
            <div className="column is-2">
              <span className="is-hidden-desktop">Date :&nbsp;</span>
              {order.order_carrier_date}
            </div>
            <div className="column is-3">
              <span className="is-hidden-desktop">Transporteur :&nbsp;</span>
              {order.name_carrier}
            </div>
            <div className="column is-2">
              <span className="is-hidden-desktop">Poids :&nbsp;</span>
              {order.order_carrier_weight}
            </div>
            <div className="column is-2">
              <span className="is-hidden-desktop">Frais d&apos;expédition :&nbsp;</span>
              {order.shipping_cost_tax_incl}
            </div>
            <div className="column is-3">
              <span className="is-hidden-desktop">Numéro de suivi :&nbsp;</span>
              {order.tracking_number}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

OrderDetails.propTypes = {
  identifiants: PropTypes.object,
  id_order: PropTypes.string,
  dispatch: PropTypes.func,
}

export default OrderDetails
