import React from "react"
import PropTypes from "prop-types";

import { connect } from "react-redux"
import FormAdresse from "../components/profile/formAdresse"


class AjoutAdresse extends React.Component {

  constructor(props) {
    super(props)    
  }

  render (){
      return(
        <>
        <FormAdresse propComponent={this.props} id_address={this.props.adresseSelected}/>
        </>
      )
  }

}


AjoutAdresse.propTypes = {
    dispatch: PropTypes.func,
    profile: PropTypes.object,
    adresseSelected: PropTypes.string
  }
  
  
  function mapStateToProps(state) {
    return {
      identifiants: state.identifiants,
    }
  }
  
export default connect(mapStateToProps)(AjoutAdresse)

//export default AjoutAdresse