export const TheCountries = [
  {
    id_country: "1",
    id_zone: "1",
    id_currency: "0",
    iso_code: "DE",
    call_prefix: "49",
    active: "1",
    contains_states: "0",
    need_identification_number: "0",
    need_zip_code: "1",
    zip_code_format: "NNNNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "Allemagne",
  },

  {
    id_country: "3",
    id_zone: "1",
    id_currency: "0",
    iso_code: "BE",
    call_prefix: "32",
    active: "1",
    contains_states: "0",
    need_identification_number: "0",
    need_zip_code: "1",
    zip_code_format: "NNNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "Belgique",
  },

  {
    id_country: "8",
    id_zone: "1",
    id_currency: "0",
    iso_code: "FR",
    call_prefix: "33",
    active: "1",
    contains_states: "0",
    need_identification_number: "0",
    need_zip_code: "1",
    zip_code_format: "NNNNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "France",
  },

  {
    id_country: "10",
    id_zone: "1",
    id_currency: "0",
    iso_code: "IT",
    call_prefix: "39",
    active: "1",
    contains_states: "1",
    need_identification_number: "0",
    need_zip_code: "1",
    zip_code_format: "NNNNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "Italie",
  },

  {
    id_country: "6",
    id_zone: "1",
    id_currency: "0",
    iso_code: "ES",
    call_prefix: "34",
    active: "1",
    contains_states: "0",
    need_identification_number: "1",
    need_zip_code: "1",
    zip_code_format: "NNNNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "Espagne",
  },
  {
    id_country: "15",
    id_zone: "1",
    id_currency: "0",
    iso_code: "PT",
    call_prefix: "351",
    active: "1",
    contains_states: "0",
    need_identification_number: "0",
    need_zip_code: "1",
    zip_code_format: "NNNN-NNN",
    display_tax_label: "1",
    id_lang: "1",
    name: "Portugal",
  },
]
