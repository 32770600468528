import React from "react"
import PropTypes from "prop-types"
import { PayPalButton } from "react-paypal-button-v2"
import { setPaypalOrderid } from "../../actions/index"
import { connect } from "react-redux"

class Paypal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      // Oui c'est pas très propre, mais le bouton paypal n'est pas customisable
      <>
        <div style={{ position: "relative", height: 80, width: 106, margin: "auto" }}>
          <div style={{ position: "absolute", top: 0, left: "40%" }} className="tableLivraison modePaiement button is-primary is-large">
            Paypal
          </div>
          <div
            style={{
              top: 10,
              position: "absolute",
              overflow: "hidden",
              width: 150,
              left: "40%",
            }}
          >
            <div style={{ opacity: 0.0005, height: 100, width: 500 }}>
              <PayPalButton
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          currency_code: "EUR",
                          value: (this.props.total).toFixed(2),
                        },
                      },
                    ],
                  })
                }}
                options={{
                  currency: "EUR",
                  clientId:
                    process.env.NODE_ENV == "development"
                      ? "AXdslNRyvI1H2YynXHlT5rgbS53x-vRLqR0YSlMZ_B0VoV4f79sh4bT7an7KgEWvT4XPmo-OfIJBCmcg" // dev
                      : "AQ4Ic5CFJ3ythp9Y7cTfXsut2wTmJEswJx5-kcqgQQ0Mf0BmFoiCwZeRmMZoN0V7hC6DdA75tRcpbPNT", // prod
                }}
                currency="EUR"
                shippingPreference="NO_SHIPPING"
                onApprove={(data, actions) => {
                  return actions.order.capture().then(() => {
                    this.props.dispatch(setPaypalOrderid(data.orderID))
                    return this.props.passePaiement("PayPal")
                  })
                }}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

Paypal.propTypes = {
  passePaiement: PropTypes.func,
  total: PropTypes.number,
  identifiants: PropTypes.object,
  dispatch: PropTypes.func,
}

function mapStateToProps() {
  return {}
}
export default connect(mapStateToProps)(Paypal)
