import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Obfuscate from "react-obfuscate"

import RecapCheque from "./../../components/recapPaiement/recapCheque"
import RecapVirement from "./../../components/recapPaiement/recapVirement"
import RecapPaypal from "./../../components/recapPaiement/recapPaypal"
import RecapDevis from "./../../components/recapPaiement/recapDevis"

import { passeCommande } from "./../../actions/passCommande"
import { refreshAuthFalse } from "./../../actions"
import { modePaiement } from "./../../actions"
import { useDispatch, useSelector } from "react-redux"

export const RecapPaiement = props => {
  const cart = useSelector(s => s.cart)
  const identifiants = useSelector(s => s.identifiants).identifiants
  const isRefresh = useSelector(s => s.loading.isRefresh)
  const paiement = useSelector(s => s.paiement)
  const dispatch = useDispatch()

  const [paid, setPaid] = useState(false)
  let total = 0
  let BoolTVA = true
  if (cart.invoiceAddress == null && typeof window !== "undefined") {
    navigate("/")
  } else {
    /* const invoiceAddress = props.identifiants.identifiants.addresses.find(e => e.id_address == props.cart.invoiceAddress) */
    /*  const invoice_country_zone = parseInt(invoiceAddress.id_zone) //zone : europe = 1 / hors europe = 7 / france = 9
     */

    /*  if (invoice_country_zone == 9) { */
    //francais pro et particulier : tva + frais de port
    total = cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)
    total += cart.fraisPort
    /*  } else if (invoiceAddress.ntva == "" && invoice_country_zone == 1) {
      // particulier europeen : tva
      total = props.cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)
    } else {
      //UE société=exonéré TVA /  hors UE société=exonéré TVA / hors UE particulier=exonéré TVA
      total = props.cart.products.reduce((acc, currrent) => acc + currrent.price * currrent.qty, 0)
      total = total / 1.2
      BoolTVA = false
    } */
  }
  useEffect(() => {
    dispatch(modePaiement(""))
  }, [])

  const handleSelected = () => {
    dispatch(refreshAuthFalse())
    dispatch(modePaiement(props.module))
    dispatch(passeCommande(cart.products, props.module, cart.invoiceAddress, cart.deliveryAddress, cart.modeLivraison, cart.fraisPort))
    setPaid(true)
  }

  const handleNavigate = () => {
    if (typeof window !== "undefined") {
      navigate(`/apps/commande#paiement`)
    }
  }

  if (
    !identifiants ||
    !identifiants.firstname ||
    (!identifiants.lastname && props.location.pathname !== `/`) ||
    (props.module != "Devis" && props.module != "Chèque" && props.module != "Virement") ||
    (cart.products.length === 0 && !paid)
  ) {
    if (typeof window !== "undefined") {
      navigate(`/`)
    }
    return null
  }
  const adresse = identifiants.addresses.find(e => e.id_address == cart.invoiceAddress)
  return (
    <>
      {!paid ? (
        props.module == "Devis" ? (
          <div className="marge_top_page">
            <h1>Récapitulatif de commande</h1>
            <div>
              <h3>Obtenir mon devis</h3>
              <p></p>
              <p>Voici un bref récapitulatif de votre commande :</p>

              <p>
                - Le montant total de votre commande s&apos;élève à{" "}
                {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(total))}
                {BoolTVA ? " TTC" : " HT"}
              </p>

              <p>
                - Merci de confirmer votre demande en cliquant sur le bouton &quot;Je confirme ma demande de devis&quot;
                {parseInt(adresse.id_zone) !== 9 ? " pour connaître le coût du transport dans mon pays" : ""}
                <br />
                <br />
                {parseInt(adresse.id_zone) !== 9
                  ? " Un mail vous sera envoyé avec le devis renseigné concernant le coût de transport avec en pièce jointe le RIB entreprise pour un règlement par virement si vous acceptez le devis."
                  : ""}
              </p>
              <p className="has-text-grey">
                Pour toutes questions, vous pouvez joindre notre service client : <br />
                par téléphone au : <b>03 89 06 00 93</b> choix 01 (du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h) <br />
                ou envoyer un mail à : <Obfuscate email="contact@producadre.fr" /> (une réponse vous sera faite sous 24h)
              </p>
              <p> En vous remerciant pour votre confiance.</p>
            </div>
            <button className="button is-large" onClick={() => handleSelected()}>
              Je confirme ma demande de devis
            </button>
            <br />
            <br />{" "}
            <button className="button is-medium" onClick={() => handleNavigate()}>
              Retour
            </button>{" "}
          </div>
        ) : props.module == "Chèque" ? (
          <div className="marge_top_page">
            <h1>Récapitulatif de commande</h1>
            <div>
              <h3>Paiement par {props.module}</h3>
              <p>
                <strong>Vous avez choisi de régler par {props.module}. Voici un bref récapitulatif de votre commande :</strong>
              </p>
              <p>
                - la commande sera traitée après réception du réglement par chèque (il faut impérativement indiquer le numéro de commande au dos de celui-ci)
              </p>
              <p>- l’adresse du siège de l’entreprise vous sera communiqué sur un mail après validation de la commande</p>
              <p>
                - Le montant total de votre commande s&apos;élève à{" "}
                {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(total))} TTC
              </p>
              <p>- Nos coordonnées bancaires seront affichées sur la page suivante et vous seront aussi envoyées par mail.</p>
              <p>- Veuillez confirmer votre commande en cliquant sur « je règle ma commande ».</p>

              <p className="has-text-grey">
                Pour toutes questions, vous pouvez joindre notre service client : <br />
                par téléphone au : <b>03 89 06 00 93</b> choix 01 (du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h) <br />
                ou envoyer un mail à : <Obfuscate email="contact@producadre.fr" /> (une réponse vous sera faite sous 24h)
              </p>
              <p> En vous remerciant pour votre confiance.</p>
            </div>
            <button className="button is-large is-primary" onClick={() => handleSelected()}>
              Je règle ma commande par {props.module?.toLowerCase()}{" "}
            </button>
            <br />
            <br />{" "}
            <button className="button is-medium is-primary" onClick={() => handleNavigate()}>
              Retour
            </button>{" "}
          </div>
        ) : (
          <div className="marge_top_page">
            <h1>Récapitulatif de commande</h1>
            <div>
              <h3>Paiement par {props.module}</h3>
              <p>
                <strong>Vous avez choisi de régler par {props.module}. Voici un bref récapitulatif de votre commande :</strong>
              </p>
              <p>- la commande sera traitée après réception du virement</p>
              <p>
                - Le montant total de votre commande s&apos;élève à{" "}
                {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(total))} TTC
              </p>
              <p>- Nos coordonnées bancaires seront affichées sur la page suivante et vous seront aussi envoyées par mail.</p>
              <p>- Veuillez confirmer votre commande en cliquant sur « je règle ma commande ».</p>

              <p className="has-text-grey">
                Pour toutes questions, vous pouvez joindre notre service client : <br />
                par téléphone au : <b>03 89 06 00 93</b> choix 01 (du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h) <br />
                ou envoyer un mail à : <Obfuscate email="contact@producadre.fr" /> (une réponse vous sera faite sous 24h)
              </p>
              <p> En vous remerciant pour votre confiance.</p>
            </div>
            <button className="button is-large is-primary" onClick={() => handleSelected()}>
              Je règle ma commande par {props.module?.toLowerCase()}{" "}
            </button>
            <br />
            <br />{" "}
            <button className="button is-medium is-primary" onClick={() => handleNavigate()}>
              Retour
            </button>{" "}
          </div>
        )
      ) : null}
      {paiement.modePaiement == "Chèque" && cart.recapPaiement !== null ? (
        <RecapCheque />
      ) : paiement.modePaiement == "Virement" && isRefresh ? (
        <RecapVirement dataRecap={cart.recapPaiement} />
      ) : paiement.modePaiement == "Devis" && isRefresh ? (
        <RecapDevis dataRecap={cart.recapPaiement} />
      ) : paiement.modePaiement == "PayPal" && isRefresh ? (
        <RecapPaypal dataRecap={cart.recapPaiement} />
      ) : paiement.modePaiement !== "" ? (
        <div className="marge_top_page">Chargement en cours...</div>
      ) : null}
    </>
  )
}

RecapPaiement.propTypes = {
  location: PropTypes.object,
  module: PropTypes.string,
}

export default RecapPaiement
