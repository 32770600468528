import React from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

import { connect } from "react-redux"

class InitMDP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      isSend: false,
    }
  }

  handleSubmit = event => {
    event.preventDefault()
    var url = process.env.GATSBY_API_URL + "/index.php?controller=authentification"
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        action: "initMDP",
        url: process.env.GATSBY_URL_ACTUEL,
        email: this.state.email,
        site: process.env.SITE,
      }),
    }).then(response => {
      this.setState({ isSend: true })
      return response.text()
    })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    if (this.props.identifiants.identifiants && this.props.identifiants.identifiants.firstname && this.props.identifiants.identifiants.lastname) {
      if (typeof window !== "undefined") {
        navigate(`/cadres`)
      }
    }

    return (
      <div className="section" style={{ paddingTop: "6rem" }}>
        <h2 className="has-text-centered">Mot de passe oublié&nbsp;?</h2>
        {!this.state.isSend ? (
          <div>
            <p className="has-text-centered">Veuillez renseigner l&lsquo;adresse e-mail que vous avez utilisée à la création de votre compte.</p>
            <p className="has-text-centered"> Vous recevrez un lien temporaire pour réinitialiser votre mot de passe.</p>

            <form onSubmit={this.handleSubmit}>
              <div className="level-item">
                <div className="field">
                  <label htmlFor="email" className="label has-text-centered">
                    Adresse e-mail&nbsp;:
                  </label>
                  <div className="control">
                    <input type="email" id="email" name="email" onChange={this.onChange} required className="input" placeholder="Votre adresse email" />
                  </div>
                  <p className="control control-button">
                    <button className="button is-purple" type="submit">
                      Envoyer un lien de réinitialisation
                    </button>
                  </p>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="notification is-primary">
            <p>
              Si cette adresse e-mail est enregistrée dans notre boutique, vous recevrez un lien pour réinitialiser votre mot de passe sur{" "}
              <span>{this.state.email}</span>.
            </p>
          </div>
        )}
      </div>
    )
  }
}

InitMDP.propTypes = {
  identifiants: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(InitMDP)
