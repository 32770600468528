import { fetchProductsPending, fetchProductsSuccess, fetchProductsError, removeAllProducts, login, refreshAuthTrue } from "./../actions"

export const passeCommande = (products, type, adresseFacturation, adresseLivraison, modelivraison, shipping) => {
  var url = process.env.GATSBY_API_URL + "/index.php?controller=myOrder"

  return (dispatch, getState) => {
    dispatch(fetchProductsPending())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        panier: products,
        type,
        adresseFacturation,
        adresseLivraison,
        modelivraison,
        shipping,
        site: process.env.SITE,
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        paypalOrderId: getState().cart.paypalOrderId,
      }),
    })
      .then(function(response) {
        dispatch(fetchProductsSuccess())
        return response.json()
      })
      .then(function(result) {
        dispatch(removeAllProducts(result))
        return result
      })
      .then(function(resultLogin) {
        dispatch(login(resultLogin.identifiants)) //mise a jours profile

        setTimeout(
          function() {
            dispatch(refreshAuthTrue())
          }.bind(this),
          1500
        )
      })
      .catch(function(error) {
        console.log("Request failed", error)
        dispatch(fetchProductsError())
      })
  }
}
