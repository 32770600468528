import React from "react"
import PropTypes from "prop-types"

import { getFraisPort } from "./../reducers/index"
import { connect } from "react-redux"
import { setDeliveryMethod } from "../actions"

class ChoixModeLivraison extends React.Component {
  constructor(props) {
    super(props)
    this.setDefault()
  }
  setDefault() {
    if (parseInt(this.props.customer.addresses.find(e => e.id_address == this.props.commande.deliveryAddress).id_country) === 8) {
      this.props.dispatch(setDeliveryMethod(11))
    } else {
      this.props.dispatch(setDeliveryMethod(10))
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.commande.deliveryAddress !== prevProps.commande.deliveryAddress) {
      this.setDefault()
    }
  }

  render() {
    return (
      <>
        <table className="tableLivraison">
          {parseInt(this.props.customer.addresses.find(e => e.id_address == this.props.commande.deliveryAddress).id_country) === 8 ? (
            <tbody>
              <tr className={this.props.commande.modeLivraison === 11 ? "serviceSelected" : ""}>
                <td className="delivery_option_radio" style={{ textAlign: "center" }}>
                  <input
                    type="radio"
                    name="selectLivraison"
                    checked={this.props.commande.modeLivraison === 11}
                    onClick={() => {
                      this.props.dispatch(setDeliveryMethod(11))
                    }}
                  />
                </td>
                <td>
                  <h3>Transporteur spécialisé</h3>Livraison à mon domicile par transporteur (adresse de livraison indiquée par vos soins)
                </td>
                <td className="delivery_option_price">{getFraisPort(this.props.commande.products, 7) / 1.2} € HT</td>
              </tr>
              <tr className={this.props.commande.modeLivraison === 9 ? "serviceSelected" : ""}>
                <td className="delivery_option_radio" style={{ textAlign: "center" }}>
                  <input
                    type="radio"
                    name="selectLivraison"
                    checked={this.props.commande.modeLivraison === 9}
                    onClick={() => {
                      this.props.dispatch(setDeliveryMethod(9))
                    }}
                  />
                </td>
                <td>
                  <h3>Retrait en magasin</h3>Retrait en magasin à Pouilley-les-Vignes (25115)
                </td>
                <td className="delivery_option_price">Gratuit</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr className={this.props.commande.modeLivraison === 10 ? "serviceSelected" : ""}>
                <td className="delivery_option_radio" style={{ textAlign: "center" }}>
                  <input
                    type="radio"
                    checked={this.props.commande.modeLivraison === 10}
                    name="selectLivraison"
                    onChange={() => {
                      this.props.dispatch(setDeliveryMethod(10))
                    }}
                  />
                </td>
                <td>
                  <h3>Livraison à l&apos;étranger</h3>Montant et délais à définir après l&apos;édition de votre devis
                  <br />
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    Nous ne proposons les commandes hors france que sur devis afin d&apos;ajuster les frais de port selon le pays et l&apos;encombrement.
                  </span>
                </td>
                <td className="delivery_option_price">Montant à définir</td>
              </tr>
            </tbody>
          )}
        </table>
      </>
    )
  }
}
ChoixModeLivraison.propTypes = {
  dispatch: PropTypes.func,
  commande: PropTypes.object,
  customer: PropTypes.object,
}
function mapStateToProps(state) {
  return {
    commande: state.cart,
    customer: state.identifiants.identifiants,
  }
}
export default connect(mapStateToProps)(ChoixModeLivraison)
