import React from "react"
import {Link} from "gatsby-plugin-spinner"
//import PropTypes from "prop-types"

class Virement extends React.Component {
  constructor(props) {
    super(props)
  }
  /* handleSelected = () => {
    this.props.passePaiement("Virement")
  }*/

  render() {
    return (
      <Link
        to="/apps/commande/Virement"
        className="button is-primary is-large tableLivraison modePaiement"
      >
        Virement
      </Link>
    )
  }
}

Virement.propTypes = {
  /* passePaiement: PropTypes.func,
  handleSelected: PropTypes.func */
}

export default Virement
