import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { addNewAdress, updateAdress } from "./../../actions/auth"
import { navigate } from "gatsby"
import { TheCountries } from "./CountryConst"

class FormAdresse extends React.Component {
  constructor(props) {
    super(props)

    var id_address = this.props.id_address == 0 ? null : this.props.id_address

    var my_adresse = this.props.propComponent.identifiants.identifiants.addresses.find(u => u.id_address === id_address)
    const lastname = this.props.propComponent.identifiants.identifiants.lastname
    const firstname = this.props.propComponent.identifiants.identifiants.firstname
    const alias = typeof my_adresse == "undefined" ? "Mon adresse" : my_adresse.alias
    const adresse = typeof my_adresse == "undefined" ? "" : my_adresse.address1
    const complementAdresse = typeof my_adresse == "undefined" ? "" : my_adresse.address2
    const codePostal = typeof my_adresse == "undefined" ? "" : my_adresse.postcode
    const ville = typeof my_adresse == "undefined" ? "" : my_adresse.city
    const phone_mobile = typeof my_adresse == "undefined" ? "" : my_adresse.phone_mobile === null ? "" : my_adresse.phone_mobile
    const phone_fixe = typeof my_adresse == "undefined" ? "" : my_adresse.phone_fixe === null ? "" : my_adresse.phone_fixe
    const id_country = typeof my_adresse == "undefined" ? 8 : my_adresse.id_country
    const societe = typeof my_adresse == "undefined" ? "" : my_adresse.societe
    const ntva = typeof my_adresse == "undefined" ? "" : my_adresse.ntva
    const other = typeof my_adresse == "undefined" ? "" : my_adresse.other
    this.state = {
      id_address: id_address,
      alias: alias,
      firstname: firstname,
      lastname: lastname,
      societe: societe,
      ntva: ntva,
      adresse: adresse,
      complementAdresse: complementAdresse,
      codePostal: codePostal,
      ville: ville,
      pays: id_country,
      telephone_mobile: phone_mobile,
      telephone_fixe: phone_fixe,
      other: other,
      countries: TheCountries,
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9"' ]/g, "") })
  }

  handleUploadfile = event => {
    event.preventDefault()
    if (this.props.id_address == 0) {
      this.props.dispatch(addNewAdress(this.state))
      if (this.props.panier.products.length >= 1) {
        navigate("/apps/commande")
      } else {
        navigate("/apps/mon-compte/#adresses")
      }
    } else {
      this.props.dispatch(updateAdress(this.state.id_address, this.state))
      if (typeof this.context.router !== "undefined") {
        this.context.router.history.goBack()
      } else {
        if (this.props.panier.products.length >= 1) {
          navigate("/apps/commande")
        } else {
          navigate("/apps/mon-compte/#adresses")
        }
      }
    }
    return false
  }

  render() {
    if (
      !this.props.propComponent.identifiants.identifiants ||
      !this.props.propComponent.identifiants.identifiants.firstname ||
      (!this.props.propComponent.identifiants.identifiants.lastname && this.props.propComponent.location.pathname !== `/`)
    ) {
      if (typeof window !== "undefined") {
        navigate(`/`)
      }
      return null
    }

    if (this.props.loading) {
      var my_url = window.location.href
      var selectCommande = my_url.search("commande")
      if (selectCommande !== -1) {
        if (typeof window !== "undefined") {
          //navigate(`/apps/commande`)
        }
      } else {
        if (typeof window == "undefined") {
          navigate(`/apps/mon-compte/#adresses`)
        }
      }
    }
    return (
      <div className="column is-5 container_margin">
        <form method="post" onSubmit={this.handleUploadfile} encType="multipart/form-data">
          <div className="field">
            <label className="label" htmlFor="alias">
              Alias&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="alias"
                required
                name="alias"
                onChange={this.onChange}
                className="input"
                placeholder="Votre Alias"
                value={this.state.alias}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="lastname">
              Prénom *&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="lastname"
                name="lastname"
                onChange={this.onChange}
                required
                className="input"
                value={this.props.propComponent.identifiants.identifiants.lastname}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="firstname">
              Nom *&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="firstname"
                name="firstname"
                onChange={this.onChange}
                required
                className="input"
                value={this.props.propComponent.identifiants.identifiants.firstname}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="societe">
              Société&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="societe"
                name="societe"
                onChange={this.onChange}
                className="input"
                required
                placeholder="Votre société"
                value={this.state.societe}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="ntva">
              Numéro de TVA (saisir sans les espaces)&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="ntva"
                name="ntva"
                onChange={this.onChange}
                className="input"
                placeholder="Votre Numéro de TVA"
                value={this.state.ntva}
                pattern="[A-Za-z]{2,2}[0-9]{10,11}"
                title="2 lettres suivies de 10 ou 11 chiffres"
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="adresse">
              Adresse *&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="adresse"
                name="adresse"
                onChange={this.onChange}
                required
                className="input"
                placeholder="Votre Adresse"
                value={this.state.adresse}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="complementAdresse">
              Complément d adresse&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="complementAdresse"
                name="complementAdresse"
                onChange={this.onChange}
                className="input"
                placeholder="Votre Complément d'adresse"
                value={this.state.complementAdresse}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="codePostal">
              Code postal *&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="codePostal"
                name="codePostal"
                onChange={this.onChange}
                required
                className="input"
                placeholder="Votre Code postal"
                value={this.state.codePostal}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="ville">
              Ville *&nbsp;:
            </label>
            <div className="control">
              <input
                type="text"
                id="ville"
                name="ville"
                onChange={this.onChange}
                required
                className="input"
                placeholder="Votre Ville"
                value={this.state.ville}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="pays">
              Pays *&nbsp;:
            </label>
            <div className="control select">
              <select name="pays" onBlur={this.onChange} defaultValue={parseInt(this.state.pays)}>
                {this.state.countries.map((node, index) => (
                  <option key={index} value={node.id_country} selected={this.state.id_country == node.id_country}>
                    {node.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4">
            <small>si votre lieu de facturation est hors de ces pays : aucune livraison ne sera possible y compris les DOM TOM</small>
          </div>

          <div className="field">
            <label className="label" htmlFor="telephone_mobile">
              Téléphone&nbsp; mobile:
            </label>
            <div className="control">
              <input
                type="tel"
                id="telephone_mobile"
                name="telephone_mobile"
                onChange={this.onChange}
                className="input"
                placeholder="Votre téléphone mobile"
                value={this.state.telephone_mobile}
              />
            </div>
          </div>
          <div className="field">
            <label className="label" htmlFor="telephone_fixe">
              Téléphone&nbsp; fixe:
            </label>
            <div className="control">
              <input
                type="tel"
                id="telephone_fixe"
                name="telephone_fixe"
                onChange={this.onChange}
                className="input"
                placeholder="Votre téléphone fixe"
                value={this.state.telephone_fixe}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="other">
              Informations supplémentaires &nbsp;:
            </label>
            <div className="control">
              <textarea
                type="textarea"
                id="other"
                name="other"
                onChange={this.onChange}
                className="input"
                placeholder=" Informations supplémentaires"
                value={this.state.other}
              />
            </div>
          </div>
          {this.state.telephone_fixe.length == 0 && this.state.telephone_mobile.length == 0 ? (
            <div className="notification is-danger">Vous devez renseigner au moins un numéro de téléphone</div>
          ) : [97, 98].includes(parseInt(this.state.codePostal.substr(0, 2))) ? (
            <div className="notification is-danger">Livraison impossible dans les DOM-TOM</div>
          ) : (
            <div className="field is-grouped" style={{ marginTop: "2rem" }}>
              <div className="control">
                <input type="submit" value="Enregistrer" className="button" />
              </div>
            </div>
          )}
        </form>
      </div>
    )
  }
}

FormAdresse.propTypes = {
  dispatch: PropTypes.func,
  propComponent: PropTypes.object,
  panier: PropTypes.object,
  id_address: PropTypes.string,
  loading: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    identifiants: state.identifiants,
    loading: state.loading.isChanged,
    panier: state.cart,
  }
}

export default connect(mapStateToProps)(FormAdresse)
