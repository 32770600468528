import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Obfuscate from "react-obfuscate"

class RecapDevis extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let country = this.props.dataRecap?.identifiants?.orders?.find(e => (e.id_order = this.props.dataRecap?.id_order))?.id_country_pad
    return (
      <>
        <div style={{ marginTop: "9rem" }}>
          <h1>
            Votre devis a bien été enregistré {parseInt(country) !== 8 ? "et une réponse vous sera faite concernant les coûts de transport sous 24h" : ""}
          </h1>

          <p>Ce dernier vous a été envoyé par e-mail. Toutefois, vous pouvez le télécharger dans la liste de vos commandes ou devis.</p>

          <p className="has-text-grey">
            Pour toutes questions, vous pouvez joindre notre service client : <br />
            par téléphone au : <b>03 89 06 00 93</b> choix 01 (du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h) <br />
            ou envoyer un mail à : <Obfuscate email="contact@producadre.fr" /> (une réponse vous sera faite sous 24h)
          </p>
          <p> En vous remerciant pour votre confiance.</p>
        </div>
      </>
    )
  }
}

RecapDevis.propTypes = {
  dataRecap: PropTypes.object,
}

//export default RecapDevis;
function mapStateToProps(state) {
  return {
    cart: state.cart,
    identifiants: state.identifiants,
  }
}

export default connect(mapStateToProps)(RecapDevis)
