import React, { useEffect, useState } from "react"

import { fetchPasseInit, login, refreshAuthTrue } from "./../../actions"
import { useDispatch, useSelector } from "react-redux"
import { clearLoading } from "../../actions/auth"

export const Reseller = () => {
  const identifiants = useSelector(s => s.identifiants).identifiants
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const [mode, setMode] = useState(identifiants.mode)
  const [margin, setMargin] = useState(identifiants.margin)

  const dispatch = useDispatch()

  const handleUploadfile = async event => {
    event.preventDefault()
    setLoading(true)

    try {
      let result = await fetch(process.env.GATSBY_API_URL + "/index.php?controller=authentification", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateReseller",
          email: identifiants.email,
          hash: identifiants.hash,
          site: process.env.SITE,
          data: { mode: mode, margin },
        }),
      })
      result = await result.json()
      if (typeof result.message !== "undefined") {
        setError(result.message)
        dispatch(clearLoading())
      } else {
        dispatch(login(result))
        setError(false)
      }
      setSuccess(true)
    } catch (err) {
      setSuccess(false)
      setError("Une erreur s'est produite !")
    }
    setLoading(false)
  }

  useEffect(() => {
    dispatch(fetchPasseInit())
    dispatch(refreshAuthTrue())
  }, [])

  return (
    <>
      <h2>Revendeur</h2>
      <ul className="column is-12">
        <div className="columns is-multiline is-centered">
          <div className="column is-10">
            {loading.loading ? (
              <div className="modal" style={{ display: "flex", textAlign: "center" }}>
                <div className="modal-background"></div>
                <div className="modal-content">
                  <button
                    className="button is-loading"
                    style={{
                      background: "transparent",
                      border: "1px solid transparent",
                    }}
                  >
                    Chargement
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}

            <form method="post" onSubmit={handleUploadfile}>
              <div className="field">
                <div className="control">
                  <label className="label" htmlFor="id_gender">
                    Mode revendeur
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={e => setMode(e.target.checked)} value="1" checked={mode} name="mode" />
                    &nbsp;Oui
                  </label>
                  <label className="radio">
                    <input type="radio" onChange={e => setMode(!e.target.checked)} value="0" checked={mode === false} name="mode" />
                    &nbsp;Non
                  </label>
                </div>
              </div>

              <label className="label" htmlFor="firstname">
                Marge
              </label>
              <div className="field has-addons">
                <div className="control">
                  <input
                    type="number"
                    min="0"
                    value={margin}
                    onChange={e => setMargin(e.target.value)}
                    required
                    className="input"
                    step="0.1"
                    placeholder="Votre marge"
                  />
                </div>
                <div className="control">
                  <span className="button is-static">%</span>
                </div>
              </div>

              <div className="columns has-text-right" style={{ marginTop: "2rem" }}>
                <div className="column">
                  <input type="submit" value="Enregistrer" className={`button is-primary is-large ${loading ? "is-loading" : ""}`} />
                </div>
              </div>
            </form>
            {error ? <div className="notification is-danger mt-4">{error}</div> : null}
            {success ? <div className="notification is-success mt-4">Information mise à jour avec succès.</div> : ""}
          </div>
        </div>
      </ul>
    </>
  )
}

Reseller.propTypes = {}

export default Reseller
