import React from "react"
import { useSelector } from "react-redux"

export const RecapCheque = () => {
  const cart = useSelector(s => s.cart)

  return (
    <>
      <div style={{ marginTop: "9rem" }}>
        <p>
          <h2>Votre commande sur france-cadre.fr a bien été enregistrée.</h2>
          <br />
          <h3>Veuillez nous envoyer un chèque avec :</h3>
          <br />
          <br />- Montant du règlement :
          <span>{new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(parseFloat(cart.recapPaiement.total))}</span>
          <br />
          <br />- payable à l’ordre de <span>{cart.recapPaiement.nameDestinationPaiement}</span>
          <br />
          <br />- à envoyer à <span>{cart.recapPaiement.addressDestinationPaiement}</span>
          <br />
          <br />- N’oubliez pas d’indiquer votre référence de commande <span>{cart.recapPaiement.id_order}</span>.
          <br />
          <br />
          Un e-mail contenant ces informations vous a été envoyé.
          <br />
          <br />
          <span>Votre commande vous sera envoyée dès réception du paiement.</span>
          <br />
          <br />
          Pour toute question ou information complémentaire merci de contacter notre support client.
        </p>
      </div>
    </>
  )
}

export default RecapCheque
